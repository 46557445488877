<template>
  <div class="permissionBox fatherHeight flex-start">
    <div class="permission-left contBox">
      <el-scrollbar ref="schemeRef" id="resultScroll">
        <div
          class="item"
          v-for="item in roleList"
          :key="item.id"
          @click="setUpBut(item)"
          :class="item.id === actSetUpData.id ? 'active' : ''"
        >
          <div class="lable ellipsis">{{ item.role_name }}</div>
        </div>
      </el-scrollbar>
    </div>
    <div class="menu-right contBox">
      <div class="parTree" v-loading="treeLoading">
        <el-scrollbar>
          <el-tree
            :data="rolePermission"
            default-expand-all
            :props="defaultProps"
          >
            <template #default="{ node, data }">
              <div class="permissionItem">
                <div class="treeLabel">{{ node.label }}</div>
                <div
                  class="permission-checkBox"
                  v-if="
                    (!data.children || data.children.length <= 0) &&
                    data.menuPermission &&
                    data.menuPermission.length > 0
                  "
                >
                  <el-checkbox
                    v-for="item in node.data.menuPermission"
                    :key="item.id"
                    v-model="item.show"
                    :label="item.permission.name"
                  />
                </div>
              </div>
            </template>
          </el-tree>
        </el-scrollbar>
      </div>

      <div class="menu-footer">
        <el-button
          @click="savePermission"
          :loading="saveLoad"
          class="color-determine"
          type="primary"
        >
          保存
        </el-button>
        <el-button
          class="color-cancel"
          type="primary"
          :loading="saveLoad"
          @click="getPermissionList"
        >
          取消
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import service from '@/utils/request'
import { ElMessage } from 'element-plus'

const roleList = ref([])
const actSetUpData = ref({})
const defaultProps = {
  children: 'children',
  label: 'name',
}
const rolePermission = ref([])
const treeLoading = ref(false)

onMounted(() => {
  getMenuList()
  document
    .getElementById('resultScroll')
    .addEventListener('scroll', handleScroll, true)
})
onBeforeUnmount(() => {
  document
    .getElementById('resultScroll')
    .removeEventListener('scroll', handleScroll, true)
})
const schemeRef = ref(null)
const searchData = reactive({
  current: 1,
  size: 20,
})
const handleScroll = async () => {
  let scrollbarEl = schemeRef.value.wrap$
  const scrollTop = scrollbarEl.scrollTop
  const scrollHeight = scrollbarEl.scrollHeight
  const offsetHeight = scrollbarEl.offsetHeight
  if (offsetHeight + scrollTop == scrollHeight) {
    searchData.current++
    getMenuList()
  }
}
const getMenuList = async () => {
  let res = await service.post('/api/role/list', searchData)
  if (res.code === 0) {
    if (searchData.current == 1) {
      roleList.value = res.data.records
      if (roleList.value.length > 0) {
        actSetUpData.value = roleList.value[0]
        getPermissionList()
      }
    } else {
      roleList.value = roleList.value.concat(res.data.records)
    }
  }
}
const getPermissionList = async () => {
  treeLoading.value = true
  let res = await service.post('/api/RolePermission/getRoleMenu', {
    role_id: actSetUpData.value.id,
  })
  treeLoading.value = false
  if (res.code === 0) {
    rolePermission.value = res.data
  }
}
const setUpBut = (item) => {
  actSetUpData.value = item
  getPermissionList()
}
const menus = ref([])
const savePermission = async () => {
  menus.value = []
  getPermissionId(rolePermission.value)
  console.log(menus.value)
  let res = await service.post('/api/role_permission/save_permission', {
    role_id: actSetUpData.value.id,
    menus: menus.value,
  })
  if (res.code === 0) {
    ElMessage.success(res.msg)
  }
}
const getPermissionId = (item) => {
  item.map((obj) => {
    if (obj.children && obj.children.length > 0) {
      getPermissionId(obj.children)
    } else {
      let par = {
        id: obj.id,
        menuPermission: obj.menuPermission,
      }
      menus.value.push(par)
    }
  })
}
</script>

<style scoped lang="scss">
.permission-left {
  padding: 8px;
  width: 200px;
  margin-right: 8px;
  .item {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Medium';
    color: #444444;
    padding-right: 14px;
    margin-bottom: 6px;
    .lable {
      background-color: #ffffff;
      border-radius: 8px 8px 8px 8px;
      padding-left: 20px;
      cursor: pointer;
    }
    &.active {
      border-right: 4px solid #3166ae;
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
    &:hover {
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
  }
}
.menu-right {
  flex: 1;
  .permissionItem {
    width: calc(100% - 24px);
    // margin-left: 2px;
    .treeLabel {
      height: 24px;
      line-height: 24px;
    }
    .permission-checkBox {
      border: 1px solid rgba(49, 102, 174, 0.1);
      border-radius: 4px;
      display: inline-block;
      padding: 3px 10px;
      margin-top: 4px;
      margin-bottom: 8px;
    }
  }
  &:deep(.el-tree) {
    .el-tree-node:focus > .el-tree-node__content,
    .el-tree-node:hover > .el-tree-node__content {
      background-color: #ffffff;
    }
    .el-tree-node__content {
      height: auto;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    // .el-tree-node__expand-icon {
    //   width: 10px;
    //   height: 10px;
    //   transform: none;
    //   position: relative;
    //   background: url('../../../assets/img/system/spread.png');
    //   background-size: 100% 100%;
    // }
    // .expanded {
    //   background: url('../../../assets/img/system/pack.png');
    //   background-size: 100% 100%;
    // }
    // .is-leaf {
    //   background-image: none;
    //   background-size: 100% 100%;
    // }
  }
}
.parTree {
  height: calc(100% - 46px);
  margin-bottom: 10px;
}
</style>
